
import Mixins from '@/shared.js'
export default {
  name: 'SeasonDashboardPage',
  mixins: [Mixins],
  data () {
    return {
      schedule: [],
      seasons: null,
      selected: {
        id: null,
        name: null,
        start: new Date(),
        end: new Date()
      }
    }
  }
}
